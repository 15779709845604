import mainLayout from "@/layout/mainLayout.vue"

const shareholdersRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/space/spaceLayout.vue"),

    children: [
      {
        path: "election/shareholders",
        name: "shareholdersList",

        component: () => import("@/views/space/shareholders/List.vue")
      },
      {
        path: "election/shareholders/add",
        name: "addShareholder",

        component: () => import("@/views/space/shareholders/Add.vue")
      }
    ]
  }
]
export default shareholdersRoutes
