import axios from "axios"
import router from "../router"
import { storeToRefs } from "pinia"
import moment from "moment"
export const ai = axios.create({
  baseURL:
    import.meta.env.VITE_BASE_URL && import.meta.env.VITE_BASE_URL !== "false"
      ? import.meta.env.VITE_BASE_URL + "/api/"
      : undefined
})

let isRefreshing = true
const prepareHeaders = (config, { token, memberId, lang }) => {
  config.headers["Authorization"] = token
  config.headers["member-id"] = memberId
  config.headers["Accept-Language"] = lang
}
ai.interceptors.request.use(
  async (config) => {
    const { refreshToken } = useAuthStore()
    const { getCurrentMemberId, user } = storeToRefs(useAuthStore())
    const userFromLocalStorage = JSON.parse(localStorage.getItem("user"))

    if (userFromLocalStorage?.token) {
      let token = userFromLocalStorage.token
      const memberId = JSON.parse(localStorage.getItem("user")).member_id

      prepareHeaders(config, {
        token: token,
        memberId: memberId,
        lang: "ar"
      })

      let tokenExpiredAfterOneMinute =
        userFromLocalStorage?.exp &&
        moment(userFromLocalStorage?.exp * 1000).diff(moment(), "minutes") <= 5
      if (tokenExpiredAfterOneMinute && isRefreshing) {
        isRefreshing = false
        await refreshToken(userFromLocalStorage.refresh_token)
        isRefreshing = true
        let newUser = JSON.parse(localStorage.getItem("user"))
        const memberId = JSON.parse(localStorage.getItem("member_id"))
        prepareHeaders(config, {
          token: newUser.token,
          memberId: memberId || userFromLocalStorage.member_id,
          lang: "ar"
        })
        return config
      }
    }
    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

ai.interceptors.response.use(
  (response) => {
    return Promise.resolve({ data: response.data.message })
  },
  async (error) => {
    if (error.response?.status === 403) {
      agToaster("error", "ليس لديك صالحيه لهذه العمليه ", "", {
        name: "phX",
        bg: "!bg-[#F24B4B]"
      })
      router.push("/")
      return Promise.reject(error.response)
    }
    if (
      error.response?.status == 401 &&
      router.currentRoute.value.name !== "login"
    ) {
      // alert("you not have permission")
      router.push("/login")
      localStorage.clear()
    }

    if (error.response.status == 417) {
      agToaster(
        "error",
        error.response?.data?.message?.error ??
          "something went wrong , try again",
        "",
        {
          name: "phX",
          bg: "!bg-[#F24B4B]"
        }
      )
    }
    if (error.response?.status == 500) {
      // router.push({ name: "notFound" });
    } else {
      console.log(error)
      return Promise.reject(error.response)
    }
  }
)
