<template>
  <div class="flex flex-col min-h-[100dvh]">
    <div
      class="flex justify-center items-center bg-primary py-[4rem] sm:py-[85px]"
    >
      <img
        :src="getImageFromAssets('images/icons/agendaLogo.svg')"
        class="self-center aspect-square w-[60px] h-[60px]"
        alt="Agenda Logo"
      />
    </div>
    <div class="bg-[#FBFBFB] h-full">
      <router-view v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </div>
    <div class="py-2 gap-5 items-center flex justify-center">
      <router-link to="/terms-and-conditions">{{
        $t("termsAndConditions")
      }}</router-link>
      <div class="point"></div>
      <router-link to="/privacy-policy">{{ $t("privacyPolicy") }}</router-link>
      <div class="point"></div>
      <router-link to="/contact-us">{{ $t("contactUS") }}</router-link>
    </div>
    <!-- <CopyRight /> -->
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
  components: {},
  data() {
    return {
      openToolTip: false
    }
  },
  methods: {}
}
</script>
