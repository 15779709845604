import mainLayout from "@/layout/mainLayout.vue"
const entitySettings = [
  {
    path: "/entity/:entityId/settings",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/entity/settings/EntityLayout.vue"),
    children: [
      {
        path: "basicData",
        name: "entity-settings-basicData",
        component: () => import("@/views/entity/settings/basicData.vue")
      },
      {
        path: "preferences",
        name: "entity-settings-preferences",

        component: () => import("@/views/entity/settings/preferences.vue")
      },
      {
        path: "adding-members",
        name: "entity-settings-adding-members",

        component: () => import("@/views/entity/settings/addingMembers.vue")
      },
      {
        path: "managers",
        name: "entity-settings-managers",

        component: () => import("@/views/entity/settings/managers.vue")
      },
      {
        path: "members",
        name: "entity-settings-members",
        component: () => import("@/views/entity/settings/members.vue")
      }
    ]
  }
]

export default entitySettings
