import mainLayout from "@/layout/mainLayout.vue"

const calenderRoutes = [
  {
    path: "/entities/:entity_id/calender/table",
    name: "entityCalender",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/entity/calender/table")
  },
  {
    path: "/entities/:entity_id/calender/timeline",
    name: "entityTimeLine",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/entity/calender/timeLine")
  }
]

export default calenderRoutes
