import mainLayout from "@/layout/mainLayout.vue"
// import committeeElectionLayout from "@/layout/committeeElectionLayout.vue"

const electionsRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/space/spaceLayout.vue"),

    children: [
      {
        path: "election",
        name: "election",

        component: () => import("@/views/space/election/election.vue")
      },
      {
        path: "election/bookmark",
        name: "electionBookmark",
        meta: {
          layout: mainLayout,
          authGard: true
        },

        component: () =>
          import("@/views/space/election/bookmark/electionBookmark.vue")
      },
      {
        path: "election/add",
        name: "addNewElection",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/election/add.vue")
      },
      // shareholder view
      {
        path: "election/apply/:election_id",
        name: "shareholderApplying",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () =>
          import("@/views/space/election/shareholder/shareholderApplying.vue")
      },
      {
        path: "election/active/details/shareholder/:election_id",
        name: "activeElectionDetailsForShareholder",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () =>
          import(
            "@/views/space/election/shareholder/electionShareholderView.vue"
          )
      },

      // admin flow
      {
        path: "election/committee/:election_id",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        name: "committeeElectionLayout",
        component: () =>
          import("@/views/space/election/details/committeeElectionLayout.vue"),
        children: [
          {
            path: "details",
            name: "details",

            component: () =>
              import("@/views/space/election/details/electionDetails.vue")
          },
          {
            path: "nomination",
            name: "nomination",

            component: () =>
              import("@/views/space/election/details/electionNominations.vue")
          },

          {
            path: "voting",
            name: "voting",

            component: () =>
              import("@/views/space/election/details/electionVoting.vue")
          },
          {
            path: "ended",
            name: "ended",

            component: () =>
              import("@/views/space/election/details/electionEnded.vue")
          }
        ]
      },
      {
        path: "election/committee/:election_id/applications/:application_id",
        name: "applicationRevision",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () =>
          import("@/views/space/election/details/applicationRevision.vue")
      },
      // -- admin edit single flow

      {
        path: "election/edit-periods/:election_id",
        name: "editElectionPeriod",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/election/editPeriods.vue")
      },
      {
        path: "election/edit-basic/:election_id",
        name: "editElectionBasic",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/election/editBasic.vue")
      },
      {
        path: "election/edit-voting/:election_id",
        name: "editElectionVoting",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/election/editVoting.vue")
      }
    ]
  }

  // {
  //   path: "/entities/:entity_id/space/:space_id/election",
  //   name: "election",
  //   meta: {
  //     layout: mainLayout,
  //     authGard: true
  //   },

  //   component: () => import("@/views/space/election/election.vue")
  // },

  // Election Bookmark

  // --- end committee Election Details

  // show dt and applay

  // add
  // {
  //   path: "/entities/:entity_id/space/:space_id/election/add",
  //   name: "addNewElection",
  //   meta: {
  //     layout: mainLayout,
  //     authGard: true
  //   },
  //   component: () => import("@/views/space/election/add.vue")
  // },
]
export default electionsRoutes
