<template>
  <div :class="menuClasses">
    <div :class="show ? 'rounded-none app-mene-opened' : ''">
      <div :class="buttonClasses">
        <slot name="button"></slot>
      </div>
    </div>
    <transition name="slide-y-transition">
      <div
        v-if="show"
        @mouseleave="emit('update:show', false)"
        @click="onContentClick"
        class="z-[9999] relative"
      >
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  perantRelative: {
    type: Boolean as PropType<boolean>,
    required: false
  },
  buttonClasses: {
    type: String as PropType<string>,
    required: false
  },
  menuClasses: {
    type: String as PropType<string>,
    required: false
  },
  show: {
    type: Boolean as PropType<boolean>,
    required: true
  }
})

const emit = defineEmits(["update:show"])

const onContentClick = (event: Event) => {
  if ((event.target as HTMLElement).closest('[name="content"]')) {
    emit("update:show", false)
  }
}
</script>

<style lang="scss">
// .slide-y-transition-enter-active,
// .slide-y-transition-leave-active {
//   transition: transform 0.3s;
// }

// .slide-y-transition-enter-from,
// .slide-y-transition-leave-to {
//   transform: translateY(-100%);
// }

// .slide-y-transition-enter-to,π\
// .slide-y-transition-leave-from {
//   transform: translateY(0);
// }

.apps-menu {
  border-top: 0px !important;
  position: relative;
  margin-top: -1px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: calc(100% - 20px);
    height: 1px;
    background-color: #ffffff33;
    transform: translateX(-10px);
  }
}
</style>
