<template>
  <!-- space apps menu -->
  <ag-menu :show="show" menuClasses="relative w-[300px]">
    <template v-slot:button>
      <ul
        class="header-menu-btn menu-controller blur flex items-center !w-[188px] !p-0 overflow-hidden"
      >
        <ag-menu-app @click="show = !show" :key="index">
          <template v-slot:icon>
            <div class="flex items-center gap-15 smooth_rounded index-12">
              <ag-avatar
                :src="getImageFromAssets(`images/apps/${CurrentMenuIcon}.svg`)"
                fromLocal
                :containerClasses="`border border-[#ffffff33] rounded-[9px] w-[32px] h-[32px] bg-[${CurrentMenuIconBg}]`"
              />
            </div>
          </template>
          <template v-slot:title>
            <div>{{ CurrentMenuTitle }}</div>
          </template>
          <template v-slot:arrow>
            <ph-caret-down
              class="ms-auto"
              :size="18"
              weight="bold"
              color="#fff"
            />
          </template>
        </ag-menu-app>
      </ul>
    </template>
    <template v-slot:content>
      <ul class="apps-menuflex flex-col items-center justify-center">
        <ag-menu-app
          v-for="(app, index) in apps"
          :to="`/entities/${entity_id}/space/${space_id}/${app.to}`"
          :key="index"
        >
          <template v-slot:icon>
            <div class="flex items-center gap-15 smooth_rounded index-12">
              <ag-avatar
                :src="getImageFromAssets(`images/apps/${app.icon}.svg`)"
                fromLocal
                :containerClasses="`border border-[#ffffff33] rounded-[9px] w-[32px] h-[32px] bg-[${app.bg}]`"
              />
            </div>
          </template>
          <template v-slot:title> {{ app.title }} </template>
        </ag-menu-app>
        <ag-menu-app
          class="bg-[#0C1B23] blur rounded-b-none pt-2 pb-2"
          :to="`/entities/${entity_id}/space/${space_id}/create_app`"
        >
          <template v-slot:icon>
            <div class="flex items-center gap-15 smooth_rounded index-12">
              <ag-avatar
                :src="getImageFromAssets(`images/apps/add_apps.svg`)"
                fromLocal
                :containerClasses="`border border-dashed border-[#ffffff33] rounded-[9px] w-[32px] h-[32px] bg-white/10`"
              />
            </div>
          </template>
          <template v-slot:title>
            {{ $t("createApp") }}
          </template>
        </ag-menu-app>
      </ul>
    </template>
  </ag-menu>
</template>

<script lang="ts" setup>
import { useSpaceStore } from "@/store/space"
import { storeToRefs } from "pinia"
import { computed, watch, ref } from "vue"

const { spacesInfo } = storeToRefs(useSpaceStore())
const route = useRoute()
const space_id = ref(route.params.space_id)
const entity_id = ref(route.params.entity_id)
const show = ref(false)
const { t } = useI18n()
const menuTitle = ref(t("apps"))
const menuIcon = ref("apps")
const CurrentMenuTitle = computed(() => menuTitle.value)
const CurrentMenuIcon = computed(() => menuIcon.value)
const CurrentMenuIconBg = computed(() => {
  if (menuIcon.value === "apps") {
    return "#0c1b2380"
  } else {
    return "#fff"
  }
})
watch(menuIcon, () => {
  CurrentMenuIcon.value = menuIcon.value
})
watch(menuTitle, () => {
  CurrentMenuTitle.value = menuTitle.value
})

watch(
  () => route.name,
  (value) => {
    console.log(value)
    if (value === "space") {
      menuTitle.value = t("apps")
      menuIcon.value = "apps"
    } else if (value === "SpaceSetting") {
      menuTitle.value = t("gear")
      menuIcon.value = "gear"
    } else if (value === "election") {
      menuTitle.value = t("election")
      menuIcon.value = "election"
    }
  },
  { immediate: true }
)

const apps = ref([
  {
    title: t("space"),
    icon: "home",
    to: ``,
    bg: "#0c1b2380"
  },
  {
    title: t("settings"),
    icon: "gear",
    to: `settings`,
    bg: "#0c1b2380"
  },
  {
    title: t("election"),
    icon: "election",
    to: `election`,
    bg: "#fff"
  }
])

const closeMenu = () => {
  show.value = false
}

const handleClickOutside = (event: MouseEvent) => {
  const menulement = document.querySelector(".menu-controller")
  if (menulement && !menulement.contains(event.target as Node)) {
    closeMenu()
  }
}

onMounted(() => {
  document.addEventListener("click", handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside)
})
</script>

<style></style>
