import mainLayout from "@/layout/mainLayout.vue"
const userSettingsRoutes = [
  {
    path: "/user/settings",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/user/settings/userSettingLayout.vue"),

    children: [
      {
        path: "basicData",
        name: "basicData",

        component: () => import("@/views/user/settings/basicData.vue")
      },
      {
        path: "preference",
        name: "userPreference",

        component: () => import("@/views/user/settings/preference.vue")
      },
      {
        path: "security",
        name: "security",

        component: () => import("@/views/user/settings/security.vue")
      }
    ]
  }
]

export default userSettingsRoutes
