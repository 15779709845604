import { ai } from "@/plugins/axios"
import { defineStore } from "pinia"
export const useLayoutStore = defineStore({
  id: "Layout",
  state: () => ({
    color: ""
  }),
  getters: {},
  actions: {
    setColor(color) {
      this.color = color.color
    }
  }
})
