<template>
  <div class="flex flex-col h-screen">
    <div
      class="flex flex-col justify-center items-center bg-primary py-[4rem] sm:py-[24px]"
    >
      <img
        :src="getImageFromAssets('images/icons/agendaLogo.svg')"
        class="self-center aspect-square w-[60px] h-[60px]"
        alt="Agenda Logo"
      />
      <div class="sinir flex justify-center items-center ltr">
        <!-- stadio -->
        <div class="box" id="box1">s</div>
        <div class="box" id="box2">t</div>
        <div class="box" id="box3">a</div>
        <div class="box" id="box4">d</div>
        <div class="box" id="box5">i</div>
        <div class="box" id="box5">o</div>
      </div>
    </div>
    <div class="bg-[#FBFBFB] h-full">
      <router-view v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<style>
.ltr {
  direction: ltr !important;
}
.box {
  width: auto;
  height: 100px;
  margin: 0 auto;
  display: flex;
  font-size: 80px;
  padding: 10px;
  color: #fff;
}

/* Step 1: Build the Animation */
@-webkit-keyframes aniload {
  from {
    -webkit-transform: translate(0px, 1000px);
  }
  to {
    -webkit-transform: translate(0px, 0px);
  }
}

@-moz-keyframes aniload {
  from {
    -moz-transform: translate(0px, 1000px);
  }
  to {
    -moz-transform: translate(0px, 0px);
  }
}

@-ms-keyframes aniload {
  from {
    -ms-transform: translate(0px, 1000px);
  }
  to {
    -ms-transform: translate(0px, 0px);
  }
}

@-o-keyframes aniload {
  from {
    -o-transform: translate(0px, 1000px);
  }
  to {
    -o-transform: translate(0px, 0px);
  }
}

@keyframes aniload {
  from {
    transform: translate(0px, 1000px);
  }
  to {
    transform: translate(0px, 0px);
  }
}

#box1 {
  -webkit-animation: aniload 4s;
  -moz-animation: aniload 4s;
  -ms-animation: aniload 4s;
  -o-animation: aniload 4s;
  animation: aniload 4s;
}

#box2 {
  -webkit-animation: aniload 1s;
  -moz-animation: aniload 1s;
  -ms-animation: aniload 1s;
  -o-animation: aniload 1s;
  animation: aniload 1s;
}

#box3 {
  -webkit-animation: aniload 4s;
  -moz-animation: aniload 4s;
  -ms-animation: aniload 4s;
  -o-animation: aniload 4s;
  animation: aniload 4s;
}

#box4 {
  -webkit-animation: aniload 3s;
  -moz-animation: aniload 3s;
  -ms-animation: aniload 3s;
  -o-animation: aniload 3s;
  animation: aniload 3s;
}

#box5 {
  -webkit-animation: aniload 2s;
  -moz-animation: aniload 2s;
  -ms-animation: aniload 2s;
  -o-animation: aniload 2s;
  animation: aniload 2s;
}
</style>
