import mainLayout from "@/layout/mainLayout.vue"
const spaceRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/space/spaceLayout.vue"),
    children: [
      {
        path: "",
        name: "space",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/summary/space.vue")
      },
      // space settings

      {
        path: "spaceSettings",
        name: "spaceSettings",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () =>
          import("@/views/space/settings/spaceSettingsLayout.vue"),

        children: [
          {
            path: "" || "spaceBasicData",
            name: "spaceBasicData",
            meta: {
              layout: mainLayout,
              authGard: true
            },
            component: () => import("@/views/space/settings/basicData.vue")
          },
          {
            path: "preference",
            name: "preference",
            meta: {
              layout: mainLayout,
              authGard: true
            },
            component: () => import("@/views/space/settings/preference.vue")
          },
          {
            path: "spaceRotation",
            name: "spaceRotation",
            meta: {
              layout: mainLayout,
              authGard: true
            },
            component: () => import("@/views/space/settings/spaceRotation.vue")
          },
          {
            path: "spaceTrash",
            name: "spaceTrash",
            meta: {
              layout: mainLayout,
              authGard: true
            },
            component: () => import("@/views/space/settings/spaceTrash.vue")
          }
        ]
      }
    ]
  },

  {
    path: "/entities/:entity_id/space/create",
    name: "createSpace",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/space/createSpace.vue")
  }
]

export default spaceRoutes
