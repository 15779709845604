import { defineStore } from "pinia"

export const useNotificationStore = defineStore("notification", {
  state: () => ({
    notifications: []
  }),
  actions: {
    addNotification(notification) {
      const newNotification = {
        ...notification,
        id: Date.now() + Math.random()
      }
      this.notifications.push(newNotification)

      setTimeout(
        () => {
          this.removeNotification(newNotification.id)
        },
        2000 + newNotification.message.length * 50
      )
    },
    removeNotification(id: any) {
      // Create a new array to ensure reactivity
      this.notifications = this.notifications.filter((n) => n.id !== id)
    }
  }
})
