import { defineStore } from "pinia"
import { ai } from "@/plugins/axios"
import { useLayoutStore } from "@/store/layout"

// const { setColor } = useLayoutStore()

export const useSpaceStore = defineStore({
  id: "Space",
  state: () => ({
    spacesInfo: null,
    breadcrumbs: [],
    loading: true,
    forceCreateNewRotation: false
  }),
  actions: {
    async getSpaceInfo(spaceId) {
      this.loading = true
      try {
        const { data } = await ai.get(
          `method/agenda.api.space.space.get_space_details`,
          { params: { space_id: spaceId } }
        )
        this.spacesInfo = data
        const { setColor } = useLayoutStore()
        setColor(data.color)
        this.forceCreateNewRotation = false
      } catch (err) {
        console.log(err.status)

        if (err.status === 460) {
          this.spacesInfo = null
          this.forceCreateNewRotation = true
        }
        this.spacesInfo = null
      } finally {
        this.loading = false
      }
    },
    async updateBreadcrumbs(links) {
      this.breadcrumbs = links
    }
  }
})
