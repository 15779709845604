import mainLayout from "@/layout/mainLayout.vue"

const nominationRoutes = [
  // {
  //   path: "/entities/:entity_id/space/:space_id/nomination",
  //   name: "nomination",
  //   meta: {
  //     layout: mainLayout,
  //     authGard: true
  //   },

  //   component: () => import("@/views/space/nomination/nomination.vue")
  // },
  {
    path: "/entity:entity_id/space/:space_id/nomination/:nomination_id",
    name: "nomination_membership",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () =>
      import("@/views/space/nomination/nomination_membership.vue")
  },
  // acceptNominationRequset
  {
    path: "/entities/:entity_id/space/:space_id/nomination/:nomination_id/:req_id",
    name: "acceptNominationRequset",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () =>
      import("@/views/space/nomination/acceptNominationRequset.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/nomination/add",
    name: "addNomination",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/space/nomination/add.vue")
  },
  // {
  //   path: "/entities/:entity_id/space/:space_id/nomination/add-election-from-nomination",
  //   name: "addElecionFromNomination",
  //   meta: {
  //     layout: mainLayout,
  //     authGard: true,
  //   },

  //   component: () =>
  //     import("@/views/space/nomination/addElecionFromNomination.vue"),
  // },
  {
    path: "/entities/:entity_id/space/:space_id/nomination/:nomination_id/current-nomination",
    name: "currentNomination",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/space/nomination/currentNomination.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/nomination/:nomination_id/ended-nomination",
    name: "endedNonimation",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/space/nomination/endedNonimation.vue")
  }
]

export default nominationRoutes
