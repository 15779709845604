import mainLayout from "@/layout/mainLayout.vue"
const spaceMemberRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/space/spaceLayout.vue"),

    children: [
      // space members
      {
        path: "members/spaceMembers",
        name: "spaceMembers",
        meta: {
          layout: mainLayout,
          authGard: true
        },

        component: () => import("@/views/space/members/users/spaceMembers.vue")
      },
      {
        path: "members/spaceMembers/add",
        name: "addSpaceMember",
        meta: {
          layout: mainLayout,
          authGard: true
        },

        component: () =>
          import("@/views/space/members/users/addSpaceMember.vue")
      },
      {
        path: "members/edit/:member_id",
        name: "editMemberProfile",
        meta: {
          layout: mainLayout,
          authGard: true
        },

        component: () =>
          import("@/views/space/members/users/editMemberProfile.vue")
      },
      {
        path: "members/view/:member_id",
        name: "memberProfile",
        meta: {
          layout: mainLayout,
          authGard: true
        },

        component: () => import("@/views/space/members/users/memberProfile.vue")
      },

      // --- roles
      {
        path: "members/roles",
        name: "spaceRoles",
        meta: {
          layout: mainLayout,
          authGard: true
        },

        component: () => import("@/views/space/members/roles/spaceRoles.vue")
      },
      {
        path: "members/add-roles",
        name: "addRole",
        meta: {
          layout: mainLayout,
          authGard: true
        },

        component: () => import("@/views/space/members/roles/add.vue")
      },
      {
        path: "members/roles/edit/:role_id",
        name: "editRole",
        meta: {
          layout: mainLayout,
          authGard: true
        },

        component: () => import("@/views/space/members/roles/edit.vue")
      },
      // groups
      {
        path: "members/spaceGroups",
        name: "spaceGroups",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/members/groups/spaceGroups.vue")
      },
      {
        path: "members/spaceGroups/addGroup",
        name: "addGroup",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/members/groups/addGroup.vue")
      },
      {
        path: "members/spaceGroups/updateGroup/:group_id",
        name: "updateGroup",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/members/groups/updateGroup.vue")
      }
    ]
  }
]

export default spaceMemberRoutes
