<template>
  <div class="header-avatar-holder" :class="containerClasses">
    <img
      :key="imgSrc"
      loading="lazy"
      class="header-avatar"
      :class="imgClasses"
      :src="imgSrc"
      :alt="alt"
      @error="handleImageError"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue"

interface Props {
  containerClasses?: string
  imgClasses?: string
  src?: string
  fromLocal?: boolean
  empty_state?: string | object
}

// Define props
const props = withDefaults(defineProps<Props>(), {
  containerClasses: "",
  imgClasses: "",
  src: "",
  fromLocal: false,
  empty_state: ""
})

// Data
const DEFAULT_FALLBACK_URL =
  "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg"

const fallbackSrc = computed(() => {
  if (typeof props.empty_state === "string" && props.empty_state !== "") {
    return props.empty_state
  } else if (
    typeof props.empty_state === "object" &&
    props.empty_state !== null
  ) {
    // Assuming the object has a 'url' property. Adjust as needed.
    return (props.empty_state as { url?: string }).url || DEFAULT_FALLBACK_URL
  }
  return DEFAULT_FALLBACK_URL
})

const isError = ref(false)
const imgSrc = ref("")
const alt = computed(() => props.src || "Avatar")

watch(
  () => props.src,
  (newVal) => {
    if (newVal) {
      imgSrc.value =
        newVal.includes("http") || props.fromLocal
          ? newVal
          : `https://dev.agenda.42.guru/${newVal}`
    } else {
      imgSrc.value = fallbackSrc.value
    }
  },
  { immediate: true }
)

// Method to handle image error
const handleImageError = () => {
  console.log("Image failed to load")
  isError.value = true
  imgSrc.value = fallbackSrc.value
}
</script>

<style scoped>
/* Add your styles here */
</style>
