const useAssets = {
  // import i18n
  i18n: inject("i18n"),

  methods: {
    getImageFromAssets(imgFileDir) {
      // console.log('imgFileDir', imgFileDir);
      return new URL(`../assets/${imgFileDir}`, import.meta.url).href
    },
    formateDateTimeToAr(dateTime) {
      const date = new Date(dateTime)

      const day = new Intl.DateTimeFormat("en", { day: "numeric" }).format(date)
      const month = new Intl.DateTimeFormat("ar-EG", { month: "long" }).format(
        date
      )
      const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
        date
      )

      // Format time
      const hour = date.getHours()
      const minute = date.getMinutes()
      const period = hour < 12 ? "صباحًا" : "مساءً"

      // Convert to 12-hour format
      const hour12 = hour % 12 || 12

      // Format time string
      const time = `${hour12.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")} ${period}`

      return `${day} ${month} ${year} ${time}`
    },
    downloadFile(fileUrl, fileName) {
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob)
          const link = document.createElement("a")
          link.href = url
          link.download = fileName
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          URL.revokeObjectURL(url)
          agToaster("success", this.$t("fileDownloadedSuccessfully"), "", {
            name: "phCheck",
            bg: "!bg-[#00C48C] !text-white"
          })
        })
        .catch((error) => {
          console.error("Error downloading the file:", error)
        })
    },
    safeTranslate(key) {
      try {
        return this.$t(key)
      } catch (error) {
        console.error(`Translation error for key "${key}":`, error)
        return key
      }
    },

    copyShareLink(link) {
      navigator.clipboard.writeText(link)
      agToaster("success", this.$t("linkCopiedSuccessfully"), "", {
        name: "phCheck",
        bg: "!bg-[#00C48C] !text-white"
      })
    }
  }
}

export default useAssets
